import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, Grid, Typography } from "@mui/material";
import logo from '../betflaglive-blu.svg';

const NewsGridItem = (props) => {

    // const date = new Date(props.newsItem.date).toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year:'numeric'}) + " | " + new Date(props.newsItem.date).toLocaleTimeString('it-IT', {hour: '2-digit', minute:'2-digit'})
    const date = new Date(props.newsItem.date).toLocaleDateString('it-IT', {day: '2-digit', month: '2-digit', year:'numeric'});
    let imageUrl = logo;

    try {
      if (props.newsItem._embedded['wp:featuredmedia'] != null) {
        imageUrl = process.env.REACT_APP_NEWS_BASE_URL + props.newsItem._embedded['wp:featuredmedia'][0].source_url;
      }
    } catch (e) {
        console.log(e);
    }

    return (<Grid item xs={12} sm={6} md={4}>
    <Card variant="outlined" sx={{display: 'flex', flexDirection: 'column', height: "100%"}}> 
    {/* sx={{ maxWidth: 345 }} */}
    <CardHeader disableTypography sx={{textAlign: 'center', padding: '6px'}}
    subheader={
      <Typography variant="body2" color="text.secondary" fontWeight={600}>
      {date}
      </Typography>
      }>
    </CardHeader>
          <CardMedia
            component="img"
            height="220"
            // image={props.newsItem.yoast_head_json.og_image[0].url}
            image={imageUrl}
            alt={props.newsItem.title.rendered}
          />
          <CardContent sx={{flex: 1}}>
            <Typography fontWeight={600} fontSize={"1.1rem"} textAlign={"center"} gutterBottom variant="h5" component="div" dangerouslySetInnerHTML={{__html: props.newsItem.title.rendered}}>
              {/* {props.newsItem.title.rendered} */}
            </Typography>
            <Typography variant="body2" color="text.secondary" >
              {/* {props.newsItem.yoast_head_json.description} */}
            </Typography>
          </CardContent>
        <CardActions disableSpacing style={{justifyContent: 'center'}}>
          <Button size="small" variant="contained" color="primary" onClick={() => props.onItemClick(props.newsItem)}>
            Leggi
          </Button>
        </CardActions>
      </Card>
      </Grid>);
};

export default NewsGridItem;