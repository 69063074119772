import { ListItem, ListItemAvatar, ListItemText, Skeleton } from "@mui/material";

const SkeletonListItem = () => {
    return (
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <Skeleton variant="circular" width={40} height={40} />
            </ListItemAvatar>
            <ListItemText>
                <Skeleton variant="text" />
                <Skeleton variant="text" width={120} />
            </ListItemText>
        </ListItem>
    )
};

export default SkeletonListItem;