import { Helmet } from 'react-helmet';
import './Home.css';
import { useMediaQuery } from '@mui/material';
import SeoSection from '../components/SeoSection';

const Home = (props) => {
    const matches = useMediaQuery('(max-width:550px)');
    let iframeHeight = 2000 + "px";
    if (matches) {
        iframeHeight = window.innerHeight + "px";
    }

    return (
        <>
            <Helmet>
                <title>Risultati in diretta di Calcio - Livescore | BetFlagLive</title>
                <meta name="description" content="Segui i risultati in diretta di calcio su BetFlagLive. Rimani sempre aggiornato sui punteggi e le partite in corso grazie al nostro livescore affidabile e preciso."/>
            </Helmet>
            <iframe title="Livescore" src="https://ls.sir.sportradar.com/betsi/it" width="100%" height={iframeHeight} marginHeight={0} marginWidth={0} />
            
            <SeoSection page={'home'} />
        </>
    )
}

Home.currentPage = "home";

export default Home;