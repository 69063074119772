import { Box, Container, Fab, Fade, useScrollTrigger } from "@mui/material";
import Footer from "./Footer";
// import NavBar from "./NavBar";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DrawerNavBar from "./DrawerNavBar";

function ScrollTop(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor',
        );

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                {children}
            </Box>
        </Fade>
    );
}

const Layout = (props) => {
    return (
        <>
            <DrawerNavBar  />
            <Container id="back-to-top-anchor" sx={{ pt: 12, pb: 4 }} maxWidth="lg">
                {props.children}
            </Container>
            <Footer />
            <ScrollTop {...props}>
                <Fab color="primary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
        </>
    );
}

export default Layout;