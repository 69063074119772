import { Paper } from "@mui/material";
import { SeoHomeCalcio, SeoNewsCalcio } from "../shared/seo";
import './SeoSection.css';

const SeoSection = (props) => {
    const getSeoContent = () =>
    {
        switch(props.page) {
            case "home":
                return SeoHomeCalcio;
            case "news":
                return SeoNewsCalcio;
            default:
                return SeoNewsCalcio;
        }
    };

    return <Paper className="seo-section" variant="outlined" sx={{marginTop:2, padding:2}}>
            { <div dangerouslySetInnerHTML={{ __html: getSeoContent() }}></div> } 
        </Paper>;
};

export default SeoSection;